// src/App.tsx
import React, { useState } from 'react';
import SearchBar from './components/SearchBar';
import styles from './App.module.css';

const App: React.FC = () => {
  const [results, setResults] = useState<string[]>([]);

  const handleSearch = (query: string) => {
    const staticData = ['apple', 'banana', 'orange', 'grape', 'strawberry'];
    const filteredResults = staticData.filter(item =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    setResults(filteredResults);
  };

  return (
    <div className={styles.container}>
      <h1>Search Bar App</h1>
      <SearchBar onSearch={handleSearch} />
      <ul className={styles.resultsList}>
        {results.map((result, index) => (
          <li key={index} className={styles.resultItem}>
            {result}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
